import apiClient from './api';

export async function getAllContests(domain = 'some.com') {
  try {
    const query = {
      domain,
    };
    const response = await apiClient({
      microservice: 'core',
      module: 'contests/bydomain',
      method: 'get',
      query,
    });
    return response.data;
  } catch (err) {
    console.info('Tenants.getAllContest error: ', err);
    throw new Error(`Tenants.getAllContest error: ${err.toString()}`);
  }
}

export default {
  getAllContests,
};
