const configDev = {
  ENDPOINT_URL: 'http://localhost:3500',
  UPLOAD_URL: 'http://localhost:3500/core/upload',
  API_KEY: 'asdn1389dn8uncx9128cnbalkscn289322m,,0-2j2231,231d9d9d3j1i0jakisdajdd03mFFJn3uik',
};

const configProd = {
  ENDPOINT_URL: 'https://api.kisscam.net',
  UPLOAD_URL: 'https://api.kisscam.net/core/upload',
  API_KEY: 'asdn1389dn8uncx9128cnbalkscn289322m,,0-2j2231,231d9d9d3j1i0jakisdajdd03mFFJn3uik',
};

export const localPorts = {
  core: 3500,
};

let LOCAL = false;
let __DEV__ = false;

/* eslint no-undef: "off" */
if (process.env.NODE_ENV === 'development') {
  LOCAL = true;
  __DEV__ = true;
}

export const __LOCAL__ = LOCAL;

export default __DEV__ ? configDev : configProd;
