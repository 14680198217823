import React from 'react';

import Routers from './routers';

function App() {
  return (
    <Routers />
  );
}

export default App;
