import React, {
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { TenantProvider } from './TenantContext';
import { getTenantInformation } from '../libs/tenants';
import { getAllContests } from '../libs/contest';

function TenantProviderContent({ children, domain }) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    tenantData: null,
    activeContest: null,
    status: 0,
  });

  async function fetchTenantData() {
    try {
      const storeData = await getTenantInformation(domain);
      if (storeData) {
        if (!storeData.tenantData.active) {
          navigate('/kisscam-contests');
        }
        const contestsData = await getAllContests(domain);
        let finalDomain = '';
        if (window.location.port === 80 || window.location.port === 443 || window.location.port === '') {
          finalDomain = `https://${storeData.tenantData.domain}`;
        } else {
          finalDomain += `http://${storeData.tenantData.domain}:${window.location.port}`;
        }
        const hasActiveContest = contestsData.docs.filter((contest) => contest.status === 'active' || contest.status === 'finalists' || contest.status === 'finished').pop();
        if (!hasActiveContest) {
          navigate('/no-contests');
        }
        setData({
          ...data,
          tenantData: { ...storeData.tenantData },
          activeContest: hasActiveContest,
          status: 1,
          finalDomain,
        });
      } else {
        navigate('/kisscam-contests');
        setData({
          ...data,
          status: 2,
        });
      }
    } catch (err) {
      console.info('Error fetch tenant data: ', err);
    }
  }

  useEffect(() => {
    let intervalId = null;
    if (domain) {
      fetchTenantData();
      intervalId = setInterval(() => {
        fetchTenantData();
      }, 5 * 1000);
    }
    return () => clearInterval(intervalId);
  }, [domain]);

  return (
    <TenantProvider
      value={{
        ...data,
        fetchTenantData,
      }}
    >
      {children}
    </TenantProvider>
  );
}

export default TenantProviderContent;
