import React, {
  useContext,
} from 'react';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Link } from 'react-router-dom';

import { getTenantBackgroundImage, getTenantLogoImage } from '../../utils/tenants';
import Screen from '../Screen';
import TenantContext from '../../providers/TenantContext';
import logo from '../../assets/logo.png';
import './styles.css';

function NotFound() {
  const context = useContext(TenantContext);
  const {
    tenantData,
  } = context;
  return (
    <Screen
      background={getTenantBackgroundImage(tenantData)}
      isMessage
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '8em',
        padding: '2em 4em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <img
          src={`${getTenantLogoImage(tenantData)}`}
          alt="tenant-logo"
          style={{
            width: '5em',
            height: '5em',
            visibility: `${(!tenantData) ? 'hidden' : 'visible'}`,
          }}
        />
        <img
          src={logo}
          alt="kisscam-logo"
          style={{
            width: '5em',
            height: '5em',
            borderRadius: '5px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '5em',
        }}
      >
        <ErrorRoundedIcon
          style={{
            color: '#1b3668',
            fontSize: '80.05px',
          }}
        />
        <h1 style={{ marginBottom: '0' }}>OOOPS!</h1>
        <h1 style={{ marginTop: '0' }}>WE CANNOT FIND THIS PAGE.</h1>
      </div>
      <Link to="/" className="item__footer__link__404"> VISIT OUR CONTESTS! </Link>
    </Screen>
  );
}

export default NotFound;
