export function getFinalists({ activeContest }) {
  return `/contest/${activeContest._id}/finalists`;
}

export function getEntries({ activeContest }) {
  return `/contest/${activeContest._id}/entries`;
}

export function getWinners({ activeContest }) {
  return `/contest/${activeContest._id}/winners`;
}

export function getStart({ activeContest }) {
  return `/contest/${activeContest._id}/start`;
}

export function getVote({ activeContest, photoData }) {
  return `/contest/${activeContest._id}/${photoData._id}/vote`;
}

export function getShare({ activeContest, photoData }) {
  return `/contest/${activeContest._id}/${photoData._id}/share`;
}

export function getUpload({ activeContest }) {
  return `/contest/${activeContest._id}/upload`;
}
