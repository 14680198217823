import React from 'react';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import SessionWidget from '../SessionWidget';
import Footer from '../Footer';

import backgroundDefault from '../../assets/bg.jpg';
import './styles.css';

function Layout({
  children,
  background = backgroundDefault,
  showLink = false,
  style,
  isMessage = false,
}) {
  return (
    <>
      <div
        className="screen"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right',
          backgroundColor: 'gray',
          backgroundBlendMode: `${(isMessage) ? 'multiply' : 'normal'}`,
          height: '100%',
          width: '100%',
          position: 'fixed',
          left: 0,
          top: 0,
          padding: 0,
          margin: 0,
        }}
      />
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <Container
          disableGutters
          maxWidth="lg"
          fixed
          style={{
            margin: 'auto',
          }}
          className="screen__container"
        >
          <div
            style={{
              maxWidth: 1024,
              background: '#fff',
              margin: 'auto',
              position: 'relative',
              boxShadow: '0 3px 6px 0 #fff',
            }}
            className="screen__item__children"
          >
            <SessionWidget />
            <div style={style} className="screen__children">
              {children}
            </div>
            {
              (isMessage) ? null : <Footer showLink={showLink} />
            }
          </div>
        </Container>
      </Box>
    </>
  );
}

export default Layout;
