import React, {
  useContext,
} from 'react';

import { Link } from 'react-router-dom';

import TenantContext from '../../providers/TenantContext';
import './styles.css';

import {
  getFinalists,
  getEntries,
  getWinners,
} from '../../utils/urls';

import IconCopy from '../IconCopy';

function Footer({
  showLink = false,
}) {
  const context = useContext(TenantContext);
  const {
    tenantData,
    activeContest,
    finalDomain,
  } = context;

  if (!tenantData || !activeContest) {
    return (<p>Loading...</p>);
  }

  let linkTo = `${getFinalists({ finalDomain, activeContest })}`;
  if (activeContest.status === 'active') linkTo = `${getEntries({ activeContest })}`;
  if (activeContest.status === 'finalists') linkTo = `${getFinalists({ activeContest })}`;
  if (activeContest.status === 'finished') linkTo = `${getWinners({ activeContest })}`;
  return (
    <footer>
      {showLink && (
        <div className="container__footer">
          <IconCopy
            link={linkTo}
            finalDomain={finalDomain}
          />
        </div>
      )}
      <div className="container__footer__link">
        <div className="item__footer">
          <Link to="/" className="item__footer__link"> Participate </Link>
          <Link to={getEntries({ finalDomain, activeContest })} className="item__footer__link"> All entries </Link>
          <Link to={getFinalists({ finalDomain, activeContest })} className="item__footer__link"> Finalists </Link>
          <Link to="/termstenant" className="item__footer__link"> Terms </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
