import React, {
  useContext,
} from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
} from 'react-router-dom';

import AuthProviderContext from './providers/AuthProvider';
import AuthContext from './providers/AuthContext';
import TenantProvider from './providers/TenantProvider';
import NotFound from './components/NotFound';
import LoadingContest from './components/LoadingContest';
import NoCurrentContests from './components/NoCurrentContests';

const Home = React.lazy(() => import('./screens/Home'));
const Login = React.lazy(() => import('./screens/Login'));
const Profile = React.lazy(() => import('./screens/Profile'));
const Contests = React.lazy(() => import('./screens/Contests'));
const Share = React.lazy(() => import('./screens/Share'));
const Vote = React.lazy(() => import('./screens/Vote'));
const Winners = React.lazy(() => import('./screens/Winners'));
const Finalists = React.lazy(() => import('./screens/Finalists'));
const Entries = React.lazy(() => import('./screens/Entries'));
const Upload = React.lazy(() => import('./screens/Upload'));
const Start = React.lazy(() => import('./screens/Start'));
const Join = React.lazy(() => import('./screens/Join'));
const TermsTenant = React.lazy(() => import('./screens/TermsTenant'));

function Fallback() {
  return (
    <LoadingContest />
  );
}

function ScreenElement({ screenName = 'Login' }) {
  const context = useContext(AuthContext);
  if (context.token && screenName === 'Start') {
    return (
      <React.Suspense fallback={<Fallback />}>
        <Upload />
      </React.Suspense>
    );
  }
  return (
    <React.Suspense fallback={<Fallback />}>
      {screenName === 'Home' && <Home />}
      {screenName === 'Login' && <Login />}
      {screenName === 'Contests' && <Contests />}
      {screenName === 'Profile' && <Profile />}
      {screenName === 'Share' && <Share />}
      {screenName === 'Vote' && <Vote />}
      {screenName === 'Finalists' && <Finalists />}
      {screenName === 'Winners' && <Winners />}
      {screenName === 'Entries' && <Entries />}
      {screenName === 'Upload' && <Upload />}
      {screenName === 'Start' && <Start />}
      {screenName === 'Join' && <Join />}
      {screenName === 'TermsTenant' && <TermsTenant />}
    </React.Suspense>
  );
}

function TenantData({ children }) {
  let location = window.location.host;
  location = location.split(':').shift();
  return (
    <TenantProvider domain={location}>
      {children}
    </TenantProvider>
  );
}

function QueryToken({ children }) {
  const [searchParams] = useSearchParams();
  const localtoken = sessionStorage.getItem('x-access-token');
  return (
    <AuthProviderContext magictoken={searchParams.get('token')} token={localtoken}>
      {children}
    </AuthProviderContext>
  );
}

function KisscamContests() {
  window.location.href = 'https://www.kisscam.com/contests';
  return null;
}

function Routers() {
  return (
    <BrowserRouter>
      <TenantData>
        <QueryToken>
          <Routes>
            <Route path="/" element={<ScreenElement screenName="Home" />}>
              <Route index element={<ScreenElement screenName="Home" />} />
            </Route>
            <Route path="/login" element={<ScreenElement screenName="Login" />} />
            <Route path="/terms" element={<ScreenElement screenName="Terms" />} />
            <Route path="/contests" element={<ScreenElement screenName="Contests" />} />
            <Route path="/termstenant" element={<ScreenElement screenName="TermsTenant" />} />

            <Route path="/contest/:id/entries" element={<ScreenElement screenName="Entries" />} />
            <Route path="/contest/:id/upload" element={<ScreenElement screenName="Upload" />} />
            <Route path="/contest/:id/:photoid/share" element={<ScreenElement screenName="Share" />} />
            <Route path="/contest/:id/:photoid/vote" element={<ScreenElement screenName="Vote" />} />
            <Route path="/contest/:id/finalists" element={<ScreenElement screenName="Finalists" />} />
            <Route path="/contest/:id/winners" element={<ScreenElement screenName="Winners" />} />
            <Route path="/contest/:id/start" element={<ScreenElement screenName="Start" />} />
            <Route path="/join/:tenantid/:magictoken" element={<ScreenElement screenName="Join" />} />

            <Route
              path="/kisscam-contests"
              element={<KisscamContests />}
            />

            <Route path="/no-contests" element={<NoCurrentContests />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryToken>
      </TenantData>
    </BrowserRouter>
  );
}

export default Routers;
