import logo from '../assets/logo.png';
import backgroundDefault from '../assets/bg.jpg';

export function getTenantLogoImage(tenantData) {
  if (tenantData && tenantData.logoimage && tenantData.logoimage.filename) {
    return tenantData.logoimage.filename;
  }
  return logo;
}

export function getTenantBackgroundImage(tenantData) {
  if (tenantData && tenantData.coverimage && tenantData.coverimage.filename) {
    return tenantData.coverimage.filename;
  }
  if (tenantData && tenantData.backgroundimage) {
    return tenantData.backgroundimage.filename;
  }
  return backgroundDefault;
}
