import apiClient from './api';

export async function getTenantInformation(domain) {
  try {
    const response = await apiClient({
      microservice: 'core',
      module: 'tenants/domain',
      method: 'get',
      query: {
        domain,
      },
    });
    return response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.dir(err);
    throw new Error(`Error lib tenants.getTenantInformation: ${err.toString()}`);
  }
}

export default {
  getTenantInformation,
};
