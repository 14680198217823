import React, { useContext } from 'react';
import AuthContext from '../../providers/AuthContext';

import './styles.css';

function SessionWidget() {
  const context = useContext(AuthContext);

  if (!context.userData) {
    return null;
  }

  const renderName = () => {
    if (context.userData) {
      return `${context.userData.firstName} ${context.userData.lastName} `;
    }
    return '';
  };

  const logout = () => {
    context.logout();
  };
  return (
    <div className="container">
      <p className="container__text">{`Hello ${renderName()}`}</p>
      |&nbsp;
      <button
        className="container__button"
        type="button"
        onClick={logout}
      >
        Clear session
      </button>
    </div>
  );
}

export default SessionWidget;
