import React, {
  useContext,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getTenantBackgroundImage } from '../../utils/tenants';
import TenantContext from '../../providers/TenantContext';
import Screen from '../Screen';
import './styles.css';

function LoadingContest() {
  const context = useContext(TenantContext);
  const {
    tenantData,
  } = context;
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <Screen
      background={getTenantBackgroundImage(tenantData)}
      isMessage
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '8em',
        padding: '3em 4em',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>LOADING...</h1>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '120px',
          height: '120px',
        }}
      >
        <CircularProgress
          size={80}
          style={{
            color: '#eb1c2d',
          }}
        />
      </div>
      <p style={{ fontSize: '1.1rem' }}>
        {'Taking too long? '}
        <button
          className="item__footer__link__retry"
          type="button"
          onClick={refreshPage}
        >
          Retry
        </button>
        .
      </p>
    </Screen>
  );
}
export default LoadingContest;
